import React from 'react'
import './catalogo.css'
import BLINDADAS from '../../assets/blindadas32.jpg'
import SUNCHOS from '../../assets/suncho.png'
import PLANAS from '../../assets/planas32.png'
import PIROMETROS from '../../assets/pirometros.jpg'
import TERMOCUPLAS from '../../assets/termocuplas32.jpg'
import CARTUCHOS from '../../assets/cartuchos32.jpg'
import ESPAGUETI from '../../assets/espagueti32.png'
import MICROBLINDADAS from '../../assets/microblind32.jpg'
import PANTALLAS from '../../assets/pantallas.jpg'
import ALETADAS from '../../assets/aletadas32.png'
import CABEZALES from '../../assets/cabezales32.jpg'
import CABEZALESTERMOTANQUE from '../../assets/cabezales.jpg'

import SSRs from '../../assets/ssrs32.png'
import DISCOS from '../../assets/discos32.png'
import MANIFOLD from '../../assets/manifold32.png'

import BORNERAS from '../../assets/borneras32.png'
import CABLES from '../../assets/cables32.png'
import TERMOSTATOS from '../../assets/termostatos32.png'


const data = [
    {
        id:1,
        image: BLINDADAS,
        title: 'Blindadas',
        detail: 'Arcos, Hornos, Evaporadores, Fundidoras de Miel.'        
    },
    {
        id:2,
        image: PLANAS,
        title: 'Planas',
        detail: 'Ancho, Largo, Watts, 220/380v. Salida: Tornillo/Cable Siliconado.'        
    },
    {
        id:3,
        image: SUNCHOS,
        title: 'Sunchos',
        detail: 'Diametro, Alto, Watts, 220/380v. Salida: Tornillo/Cable Siliconado.'        
    },
    {
        id:4,
        image: PIROMETROS,
        title: 'Pirometros',
        detail: 'J, K, PT100, Contactor. Todos los formatos.'        
    },
    {
        id:5,
        image: TERMOCUPLAS,
        title: 'Termocuplas',
        detail: 'J, K, PT100, Compactadas.'        
    },
    {
        id:6,
        image: CARTUCHOS,
        title: 'Cartuchos',
        detail: 'Diametro, Largo, Watts, 220/380v.'        
    },
    {
        id:7,
        image: ESPAGUETI,
        title: 'Espagueti',
        detail: 'Todos los Diametros.'        
    },
    {
        id:8,
        image: MICROBLINDADAS,
        title: 'Microblindadas',
        detail: 'Diametro, Largo, Watts.'        
    },
    {
        id:9,
        image: PANTALLAS,
        title: 'Pantallas Cerámicas',
        detail: 'Todas las medidas.'        
    },
    {
        id:10,
        image: ALETADAS,
        title: 'Aletadas',
        detail: 'EDOS, ARGENTAL.'        
    },
    {
        id:11,
        image: CABEZALES,
        title: 'Cabezales',
        detail: 'Termotanques, Bateas.'        
    },
    {
        id:12,
        image: CABEZALESTERMOTANQUE,
        title: 'Cabezales de Termotanque',
        detail: '1500w / 2000w.'        
    },
    {
        id:13,
        image: SSRs,
        title: 'SSR',
        detail: '3-32 / 220v.'        
    },
    {
        id:14,
        image: DISCOS,
        title: 'Discos Anafes',
        detail: '1000w / 1500w / 2000w.'        
    },
    {
        id:15,
        image: MANIFOLD,
        title: 'Blindada Manifold',
        detail: '6,1 x 6,1 x Largo Solicitado.'        
    },
    {
        id:16,
        image: BORNERAS,
        title: 'Borneras',
        detail: 'Cerámicas / Siliconas.'        
    },
    {
        id:17,
        image: CABLES,
        title: 'Cable',
        detail: 'Fibra de Vidrio, Siliconado.'        
    },
    {
        id:18,
        image: TERMOSTATOS,
        title: 'Termostatos',
        detail: 'Mecánicos, Campinis.'        
    }
]

const Catalogo = () => {
    return (
      <section id='catalogo'>
          <h5>RESISTENCIAS-SENSORES-CONTROLADORES</h5>
          <h2>CATALOGO</h2>

          <div className="container catalogo__container">
            {
                data.map(({id, image, title, detail}) =>{
                    return(
                        <article key={id} className='catalogo__item'>
                            <div className="catalogo__item-image">
                                <img src={image} alt={title} />
                            </div>
                            <h3>{title}</h3>
                            <p>{detail}</p>
                            <div className="catalogo__item-cta">
                                <a href="#contact" className='btn btn-primary' >CONTACTO</a>
                                <a href="https://api.whatsapp.com/send?phone=5491144296226&text=Hola!%20Necesito%20informaci%C3%B3n%20sobre%20un%20producto%20de%20la%20web%20" className='btn btn-succes'  target="_blank" rel="noreferrer">WHATSAPP</a>
                            </div>
                        </article>
                    )
                })
            }

          </div>

      </section>
    )
  }
  
  export default Catalogo