import React from 'react'
import './nav.css'
import LogoResisten from '../../assets/Logo-Resisten.png'

const Nav = () => {  
  return (
    <nav>
      <div className="brand"><a href="https://www.resisten.com.ar" target='_blank' rel="noreferrer"><img src={LogoResisten} alt="RE-SIS-TEN.COM" /></a></div>


      <div className="nav__menu">
        
        <a href="#catalogo">CATALOGO</a>
        <a href="#contact">CONTACTO</a>
        <a href="#about">NOSOTROS</a>
      </div>
      
      
    </nav>
  )
}

export default Nav