import React from 'react'
import './proveemos.css'
import DYNISCO from '../../assets/dynisco.png'
import ELSTEIN from '../../assets/elstein.png'

const Proveemos = () => {
  return (
    <section id='proveemos'>
        <h5>DISTRIBUIDORES OFICIALES</h5>
        <h2>PROVEEMOS</h2>
        <div className="proveemos__container">
            <article className='line'>
                
                <div className="line__head">
                    
                    <img src={DYNISCO} alt="DYNISCO" />
                    
                </div>          
            </article>

            <article className='line'>
                
                <div className="line__head">
                    
                    <img src={ELSTEIN} alt="ELSTEIN" />
                    
                </div>
            </article>

            
        </div>
    </section>
  )
}

export default Proveemos