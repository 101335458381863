import React from 'react'
import './map.css'

const Map = () => {
  return (
    <section id='map__Pintos91'>
        <div className="container map__Pintos91__container">

        <article>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3281.0989960255074!2d-58.507546384896976!3d-34.67745088044047!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcc9212ee870e1%3A0xd7e73733c704ea98!2sGral.%20Pintos%2091%2C%20B1751BJA%20La%20Tablada%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1660098458582!5m2!1ses-419!2sar" width="100%" loading="lazy"></iframe>
        
        </article>
            
        </div>
    </section>
  )
}

export default Map