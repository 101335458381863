import React from 'react'
import './footer.css'
import {BsEnvelopeFill} from 'react-icons/bs'
import {FiInstagram} from 'react-icons/fi'
import {FaLinkedinIn} from 'react-icons/fa'
import {FaFacebookSquare} from 'react-icons/fa'

const Footer = () => {
  return (
    <footer>
      <a href="https://www.resisten.com.ar/" className='footer__logo'>RE-SIS-TEN.COM</a>

      <ul className='permalinks'>
        <li><a href="#header">Inicio</a></li>
        <li><a href="#about">Nosotros</a></li>
        <li><a href="#catalogo">Catálogo</a></li>
        <li><a href="#contact">Contacto</a></li>
      </ul>

      <div className="footer__socials">
        <a href="https://www.facebook.com/Resisten-Srl-110816301703999" target='_blank' rel="noreferrer"><FaFacebookSquare/></a>
        <a href="mailto:ventas@resisten.com.ar" target='_blank' rel="noreferrer"><BsEnvelopeFill/></a>
        <a href="https://www.instagram.com/resistensrl/" target='_blank' rel="noreferrer"><FiInstagram/></a>
        <a href="https://www.linkedin.com/company/resisten-srl/" target='_blank' rel="noreferrer"><FaLinkedinIn/></a>
      </div>

      <div className="footer__copyright">
        <small>&copy; 2022 Powered by BRISIDER.</small>
      </div>
    </footer>
  )
}

export default Footer