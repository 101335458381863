import React from 'react'
import './lines.css'
import {AiOutlineClockCircle} from 'react-icons/ai'
import {GoLocation} from 'react-icons/go'
import {BsWhatsapp} from 'react-icons/bs'

const Lines = () => {
  return (
    <section id='lines'>
        <div className="lines__container">

            <article className='line'>
            <BsWhatsapp className='line__icon' />
            <div className="line__head">
                
                <h2>WATSAPP</h2>
                <h4>11-4429-6226</h4>
            </div>          
            </article>

            <article className='line'>
            <AiOutlineClockCircle className='line__icon' />
            <div className="line__head">
                
            
                <h2>HORARIO</h2>
                <h4>Lunes a Viernes:</h4>
                <h4>8-12:30hs/13:15-16hs</h4>
            </div>
            </article>

            <article className='line'>
            <GoLocation className='line__icon' />
            <div className="line__head">
            
                
                <h2>UBICACION</h2>
                <h4>GENERAL PINTOS 91</h4>
            </div>
            </article>
        </div>
    </section>
  )
}

export default Lines