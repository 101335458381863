import React from 'react'

const CTA = () => {
  return (
    <div className="cta">
        <a href="#catalogo" className='btn btn-primary'>CATALOGO</a>
        <a href="#contact" className='btn btn-primary-outline'>CONTACTANOS</a>
    </div>
  )
}

export default CTA