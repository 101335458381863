import React from 'react'
import Header from './components/header/Header'
import Footer from './components/footer/Footer'
import Nav from './components/nav/Nav'
import About from './components/about/About'
import Lines from './components/lines/Lines'
import Catalogo from './components/catalogo/Catalogo'
import Contact from './components/contact/Contact'
import Map from './components/mapa/Map'
import Proveemos from './components/proveemos/Proveemos'

const App = () => {
  return (
    <>
        <Header />
        <Nav />
        <About />
        <Lines />
        <Catalogo/>
        <Proveemos/>
        <Contact />
        <Map/>
        <Footer />
    </>
  )
}

export default App