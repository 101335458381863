import React from 'react'
import './about.css'
import US from '../../assets/resisten.png'

const about = () => {
  return (
    <section id='about'>
      <h5>Acerca De</h5>
      <h2>Nosotros</h2>

      <div className="container about__container">
        <div className="about__me">
          <div className="about__me-image">
            <img src={US} alt="RESISTEN" />
          </div>
        </div>

        <div className="about__content">

          <h2>RESISTEN</h2>
          <h5>Resistencias de Avanzada</h5>
          
          <p>
          Somos fabricantes líderes en el mercado de RESISTENCIAS ELÉCTRICAS con más de 40 años de trayectoria. 
          Tenemos stock resistencias de artículos DOMESTICOS como hornos, anafes, estufas, bebederos, pavas, etc.
          Productos como pirómetros, termocuplas o pantallas de las marcas más reconocidas en el sector industrial (Dynisco, Elstein, Taisuo, Maxwell) 
          pueden encontrarse en <a href="#map__Pintos91" class="text-info text-uppercase">nuestro local</a>.
          </p>

          <a href="#contact" className='btn btn-primary'>Contactanos</a>
        </div>
      </div>
    </section>
  )
}

export default about